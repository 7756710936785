import * as yup from 'yup';
import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Button, Input, Alert } from 'antd';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import axios from 'axios';

const StyledInput = styled.div`
  margin: 0 2px 8px 0;
`;
const SectionTitle = styled.h5`
  margin: 10px 4px 4px 0;
  color: #424242;
`;
const FormIssue = styled.div`
  color: red;
  font-size: 12px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
`;

class CheckClaimStatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
      status: '',
      type: 'success',
    };
  }

  render() {
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          orderNumber: '',
        }}
        validationSchema={yup.object().shape({
          firstName: yup.string().required('First Name is required.'),
          lastName: yup.string().required('Last Name is required.'),
          orderNumber: yup
            .string()
            .required('Reference number is required.'),
        })}
        onSubmit={async (values, actions) => {
          try {
            this.setState({
              showStatus: false,
              status: '',
            });
            const endpoint = `${process.env.API_URL}/claimstatus`;
            const response = await axios.post(endpoint, {
              orderNum: values.orderNumber,
              firstName: values.firstName,
              lastName: values.lastName,
            });

            this.setState({
              showStatus: true,
              status: response.data,
              type: 'success',
            });
            actions.setSubmitting(false);
          } catch (error) {
            this.setState({
              showStatus: true,
              status: 'Claim details not found.',
              type: 'error',
            });

            actions.setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form id="form_check_claim_status">
            <Grid>
              <Row>
                <Col xs>
                  <Row>
                    <Col xs>
                      <SectionTitle>
                        What&apos;s Your Claim Reference Number?
                      </SectionTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.firstName && errors.firstName && (
                      <FormIssue>{errors.firstName}</FormIssue>
                      )}
                      <Field
                        name="firstName"
                        id="firstName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="First Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.lastName && errors.lastName && (
                      <FormIssue>{errors.lastName}</FormIssue>
                      )}
                      <Field
                        name="lastName"
                        id="lastName"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Last Name"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {touched.orderNumber && errors.orderNumber && (
                      <FormIssue>{errors.orderNumber}</FormIssue>
                      )}
                      <Field
                        name="orderNumber"
                        id="orderNumber"
                      >
                        {({ field }) => (
                          <StyledInput>
                            <Input
                              {...field}
                              size="large"
                              placeholder="Reference Number"
                            />
                          </StyledInput>
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs>
                      <Button
                        type="primary"
                        style={{ float: 'right' }}
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      >
                        Lookup Claim
                        {' '}
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          style={{ marginLeft: '4px', fontSize: '16px' }}
                        />
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      {' '}
                      {/* eslint-disable-next-line */}
                    {this.state.showStatus ? <Alert style={{ marginTop: '10px' }} message={`Status: ${this.state.status}`} type={this.state.type} /> : ''}

                    </Col>
                  </Row>
                </Col>
              </Row>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}

export default CheckClaimStatusForm;
