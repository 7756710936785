import { StrictMode } from "react";
import { Button, Divider, Modal } from 'antd';
import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Layout from '../components/layout';
import ClaimContext from '../components/claim_context';

import claimsCover from '../images/claims_cover.png';
import claimsCover2x from '../images/claims_cover@2x.png';
import featureBg from '../images/feature_bg.png';

import CheckClaimStatusForm from '../components/forms/form_check_claim_status';

import ClaimSteps from '../components/steps';

// Create a Provider Component;
// A Top Level Provider Component for Context API to pass values down into children
class ClaimProvider extends Component {
  state = {
    step: -1,
  };

  render() {
    return (
      <ClaimContext.Provider
        value={{
          state: this.state,
        }}
      >
        {this.props.children}
      </ClaimContext.Provider>
    );
  }
}

// Main ClaimsApp entry point.  All steps are children of ClaimsApp
class ClaimsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClaimStatus: false,
    };
    this.handleCheckStatus = this.handleCheckStatus.bind(this);
    this.handleCloseClaimStatus = this.handleCloseClaimStatus.bind(this);
    this.clickDetect = this.clickDetect.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.clickDetect);
  }

  clickDetect(e) {
    const dlObject = {};
    dlObject.element = e.target;
    dlObject.elementClasses = e.target.getAttribute('class');
    dlObject.elementId = e.target.getAttribute('id');
    dlObject.elementTarget = e.target;
    dlObject.elementUrl = e.target.getAttribute('href');
    if (e.target.tagName === 'A') {
      dlObject.event = 'gmt.linkClick';
    } else {
      dlObject.event = 'gmt.click';
    }
    // dataLayer.push(dlObject);
    // dataLayer.page = dlObject;
  }

  handleCheckStatus() {
    this.setState({
      showClaimStatus: !this.state.showClaimStatus,
    });
  }

  handleCloseClaimStatus() {
    this.setState({
      showClaimStatus: false,
    });
  }

  render() {
    return (
      <ClaimContext.Consumer>
        {(context) => (
          <Layout>
            {/* Display current step's content */}
            <Grid>
              <Row>
                <Col xs>
                  <ClaimSteps />
                </Col>
              </Row>
            </Grid>
            <Grid>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h3>Welcome to AEP Claims Portal</h3>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <h5>Important - Please Read</h5>
                  <p>
                    <span>
                      AEP is committed to delivering unmatched service as we work to provide safe, reliable power.
                      There are times, however, when service may be interrupted, or damage may occur from other causes.
                      AEP understands how difficult these situations can be,
                      but AEP is generally not responsible for damages arising from service interruptions or other circumstances including:
                    </span>
                  </p>
                  <ul>
                    <li>
                      <span>Equipment failure</span>
                    </li>
                    <li>
                      <span>Animal interference</span>
                    </li>
                    <li>
                      <span>Power fluctuations or voltage surges</span>
                    </li>
                    <li>
                      <span>Storms or other weather-related conditions</span>
                    </li>
                    <li>
                      <span>Vegetation management, including tree trimming</span>
                    </li>
                    <li>
                      <span>Emergency interruptions, including load shed</span>
                    </li>
                  </ul>
                  <p>
                    <span>
                      You may want to contact your personal or business insurance company to determine if your damage is covered by your policy.
                    </span>
                  </p>
                  <p>
                    <span>
                      If you believe AEP caused damage to your property, you may click on the button below to submit a claim.
                      You will need to provide documentation such as invoices, receipts, and photographs to support your claim.
                      Failure to provide the appropriate documentation may result in a delay in evaluating your claim.
                    </span>
                  </p>
                  <p>
                    <span>
                      AEP will investigate and evaluate each claim that is submitted and will usually render a decision approving or denying your claim within 30 days.
                    </span>
                  </p>
                  <p>
                    <strong>Note:</strong> This on-line claim submission site is restricted to <u><strong>current AEP residential customers only</strong></u>.
                    If you are <u>not</u> a residential customer, please call (800) 277-2177 to speak with a customer service representative about filing a claim.
                    <br/>&nbsp;
                  </p>
                  <p>
                    <Link to="/claim">
                      <Button id="fileClaim" name="fileClaim" type="primary">Continue To File Claim</Button>
                    </Link>
                  </p>
                </Col>
                <Col xs>
                  <div>
                    <h5>Additional Resources</h5>
                  </div>
                  <div
                    style={{
                      backgroundImage: `url(${featureBg})`,
                      backgroundRepeat: 'no-repeat',
                      textAlign: 'center',
                      width: '100hv',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        width: '100hv',
                        marginBottom: '15px',
                      }}
                    >
                      <Button type="primary" onClick={this.handleCheckStatus}>
                        Check Existing Claim Status
                      </Button>
                      <Modal
                        title="Check Claim Status"
                        open={this.state.showClaimStatus}
                        onOk={this.handleCloseClaimStatus}
                        onCancel={this.handleCloseClaimStatus}
                        footer={[
                          <Button
                            key="close"
                            onClick={this.handleCloseClaimStatus}
                          >
                            Close
                          </Button>,
                        ]}
                      >
                        <CheckClaimStatusForm />
                      </Modal>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        width: '100hv',
                      }}
                    >
                      <p>
                        <Link to="/help/claims_overview/" target="_blank">
                          Read about our coverage and claims process
                        </Link>
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: '14px',
                        textAlign: 'left',
                        width: '100hv',
                      }}
                    >
                      <p>
                        <Link to="/help/claims_tips/" target="_blank">
                          Read about suggestions for submitting claims
                        </Link>
                      </p>
                    </div>
                    <img
                      src={claimsCover}
                      srcSet={`${claimsCover2x} 2x`}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>
          </Layout>
        )}
      </ClaimContext.Consumer>
    );
  }
}

ClaimsApp.contextType = ClaimContext;

// Claims entry point with Provider Wrapped around App
class App extends Component {
  render() {
    return (
      <StrictMode>
        <ClaimProvider>
          <ClaimsApp />
        </ClaimProvider>
      </StrictMode>
    );
  }
}

export default App;
